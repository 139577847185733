@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #222;
}

.fontLogo {
  font-family: 'Dancing Script', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  background-color: white;
  margin-bottom: 0;
  padding-bottom: 10px;
}

.fc-dateInformation-button.fc-button.fc-button-primary {
  width: fit-content !important;
  cursor: default !important;
}

.fc-dateInformation-button.fc-button.fc-button-primary:focus {
  box-shadow: unset !important;
}
.fc-dateInformation-button.fc-button.fc-button-primary:hover {
  background-color: #2c3e50 !important;
}
.fc-thisWeek-button {
  background-color: #303d4fc7 !important;
  border-color: #303d4fc7 !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
}

.fc-license-message {
  display: none !important;
}

.fc-timeline-event-harness {
  top: 0 !important;
}



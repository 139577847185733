.Table {
  & {
    @apply p-2;
  }

  thead {
    tr {
      th {
        &:first-child {
          @apply pl-10;
        }

        &:last-child {
          @apply pr-10;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        transform: translateY(-2px);
        backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
      }
      &:not(:last-child) {
        @apply border-b-0;
      }

      td {
        &:first-child {
          @apply pl-10;
        }

        &:last-child {
          @apply pr-10;
        }
      }
    }
  }
}
